import React, { ReactElement } from "react";

import Hero from "components/Hero";
import ImageSection from "components/ImageSection";
import { Wrapper } from "style/components/Page";

import NotFoundPageProps from "./NotFoundPageProps";

const NotFoundPage = ({
    theme = "blue",
    contentTheme = "cyan",
    actionTheme = "cyan",
    hero = {
        heading: "Page not found",
    },
    mainImage,
}: NotFoundPageProps): ReactElement => (
    <Wrapper id="maincontent">
        <Hero
            key={"Hero"}
            theme={theme}
            contentTheme={contentTheme}
            actionTheme={actionTheme}
            {...hero}
        />
        <ImageSection {...mainImage} />
    </Wrapper>
);

export default React.memo(NotFoundPage);
